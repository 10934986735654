body,
html {
  width: 100%;
  height: 100%;
}

body,
p {
  color: #f1f1f8;
  font: 400 0.875rem/1.375rem 'Open Sans', sans-serif;
}

.p-large {
  color: #f1f1f8;
  font: 400 1rem/1.5rem 'Open Sans', sans-serif;
}

.p-small {
  color: #f1f1f8;
  font: 400 0.75rem/1.25rem 'Open Sans', sans-serif;
}

.p-heading {
  margin-bottom: 3.75rem;
  text-align: center;
}

.li-space-lg li {
  margin-bottom: 0.375rem;
}

.indent {
  padding-left: 1.25rem;
}

h1 {
  color: #f1f1f8;
  font: 700 2.5rem/2.875rem 'Montserrat', sans-serif;
}

h2 {
  color: #f1f1f8;
  font: 700 2.25rem/2.75rem 'Montserrat', sans-serif;
}

h3 {
  color: #f1f1f8;
  font: 700 1.75rem/2.125rem 'Montserrat', sans-serif;
}

h4 {
  color: #f1f1f8;
  font: 700 1.375rem/1.75rem 'Montserrat', sans-serif;
}

h5 {
  color: #f1f1f8;
  font: 700 1.125rem/1.625rem 'Montserrat', sans-serif;
}

h6 {
  color: #f1f1f8;
  font: 700 1rem/1.5rem 'Montserrat', sans-serif;
}

a {
  color: #f1f1f8;
  text-decoration: underline;
}

a:hover {
  color: #f1f1f8;
  text-decoration: underline;
}

a.turquoise {
  color: #00c9db;
}

a.white {
  color: #fff;
}

.testimonial-text {
  font: italic 400 1rem/1.5rem 'Open Sans', sans-serif;
}

.testimonial-author {
  font: 700 1rem/1.5rem 'Montserrat', sans-serif;
}

.btn-solid-reg {
  display: inline-block;
  padding: 1.1875rem 2.125rem 1.1875rem 2.125rem;
  border: 0.125rem solid #00c9db;
  border-radius: 2rem;
  background-color: #00c9db;
  color: #fff;
  font: 700 0.75rem/0 'Montserrat', sans-serif;
  text-decoration: none;
  transition: all 0.2s ease;
}

.btn-solid-reg:hover {
  background-color: transparent;
  color: #00c9db;
  text-decoration: none;
}

.btn-solid-lg {
  display: inline-block;
  padding: 1.375rem 2.625rem 1.375rem 2.625rem;
  border: 0.125rem solid #00c9db;
  border-radius: 2rem;
  background-color: #00c9db;
  color: #fff;
  font: 700 0.75rem/0 'Montserrat', sans-serif;
  text-decoration: none;
  transition: all 0.2s ease;
}

.btn-solid-lg:hover {
  background-color: transparent;
  color: #00c9db;
  text-decoration: none;
}

.btn-solid-lg .fab {
  margin-right: 0.5rem;
  font-size: 1.25rem;
  line-height: 0;
  vertical-align: top;
}

.btn-solid-lg .fab.fa-google-play {
  font-size: 1rem;
}

.btn-outline-reg {
  display: inline-block;
  padding: 1.1875rem 2.125rem 1.1875rem 2.125rem;
  border: 0.125rem solid #00c9db;
  border-radius: 2rem;
  background-color: transparent;
  color: #00c9db;
  font: 700 0.75rem/0 'Montserrat', sans-serif;
  text-decoration: none;
  transition: all 0.2s ease;
}

.btn-outline-reg:hover {
  background-color: #00c9db;
  color: #fff;
  text-decoration: none;
}

.btn-outline-lg {
  display: inline-block;
  padding: 1.375rem 2.625rem 1.375rem 2.625rem;
  border: 0.125rem solid #00c9db;
  border-radius: 2rem;
  background-color: transparent;
  color: #00c9db;
  font: 700 0.75rem/0 'Montserrat', sans-serif;
  text-decoration: none;
  transition: all 0.2s ease;
}

.btn-outline-lg:hover {
  background-color: #00c9db;
  color: #fff;
  text-decoration: none;
}

.btn-outline-sm {
  display: inline-block;
  padding: 1rem 1.625rem 0.875rem 1.625rem;
  border: 0.125rem solid #00c9db;
  border-radius: 2rem;
  background-color: transparent;
  color: #00c9db;
  font: 700 0.625rem/0 'Montserrat', sans-serif;
  text-decoration: none;
  transition: all 0.2s ease;
}

.btn-outline-sm:hover {
  background-color: #00c9db;
  color: #fff;
  text-decoration: none;
}

.form-group {
  position: relative;
  margin-bottom: 1.25rem;
}

.form-group.has-error.has-danger {
  margin-bottom: 0.625rem;
}

.form-group.has-error.has-danger .help-block.with-errors ul {
  margin-top: 0.375rem;
}

.label-control {
  position: absolute;
  top: 0.87rem;
  left: 1.375rem;
  color: #f1f1f8;
  opacity: 1;
  font: 400 0.875rem/1.375rem 'Open Sans', sans-serif;
  cursor: text;
  transition: all 0.2s ease;
}

/* IE10+ hack to solve lower label text position compared to the rest of the browsers */
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .label-control {
    top: 0.9375rem;
  }
}

.form-control-input:focus + .label-control,
.form-control-input.notEmpty + .label-control,
.form-control-textarea:focus + .label-control,
.form-control-textarea.notEmpty + .label-control {
  top: 0.125rem;
  opacity: 1;
  font-size: 0.75rem;
  font-weight: 700;
}

.form-control-input,
.form-control-select {
  display: block; /* needed for proper display of the label in Firefox, IE, Edge */
  width: 100%;
  padding-top: 1.0625rem;
  padding-bottom: 0.0625rem;
  padding-left: 1.3125rem;
  border: 1px solid #9791ae;
  border-radius: 0.25rem;
  background-color: #413d52;
  color: #f1f1f8;
  font: 400 0.875rem/1.875rem 'Open Sans', sans-serif;
  transition: all 0.2s ease;
  -webkit-appearance: none; /* removes inner shadow on form inputs on ios safari */
}

.form-control-select {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: 3rem;
}

/* IE10+ hack to solve lower label text position compared to the rest of the browsers */
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .form-control-input {
    padding-top: 1.25rem;
    padding-bottom: 0.75rem;
    line-height: 1.75rem;
  }

  .form-control-select {
    padding-top: 0.875rem;
    padding-bottom: 0.75rem;
    height: 3.125rem;
    line-height: 2.125rem;
  }
}

select {
  /* you should keep these first rules in place to maintain cross-browser behavior */
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-image: url('../assets/images/down-arrow.png');
  background-position: 96% 50%;
  background-repeat: no-repeat;
  outline: none;
}

select::-ms-expand {
  display: none; /* removes the ugly default down arrow on select form field in IE11 */
}

.form-control-textarea {
  display: block; /* used to eliminate a bottom gap difference between Chrome and IE/FF */
  width: 100%;
  height: 8rem; /* used instead of html rows to normalize height between Chrome and IE/FF */
  padding-top: 1.25rem;
  padding-left: 1.3125rem;
  border: 1px solid #9791ae;
  border-radius: 0.25rem;
  background-color: #413d52;
  color: #f1f1f8;
  font: 400 0.875rem/1.75rem 'Open Sans', sans-serif;
  transition: all 0.2s ease;
}

.form-control-input:focus,
.form-control-select:focus,
.form-control-textarea:focus {
  border: 1px solid #f1f1f8;
  outline: none; /* Removes blue border on focus */
}

.form-control-input:hover,
.form-control-select:hover,
.form-control-textarea:hover {
  border: 1px solid #f1f1f8;
}

.checkbox {
  font: 400 0.75rem/1.25rem 'Open Sans', sans-serif;
}

input[type='checkbox'] {
  vertical-align: -15%;
  margin-right: 0.375rem;
}

/* IE10+ hack to raise checkbox field position compared to the rest of the browsers */
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  input[type='checkbox'] {
    vertical-align: -9%;
  }
}

.form-control-submit-button {
  display: inline-block;
  width: 100%;
  height: 3.125rem;
  border: 1px solid #00c9db;
  border-radius: 1.5rem;
  background-color: #00c9db;
  color: #fff;
  font: 700 0.75rem/1.75rem 'Montserrat', sans-serif;
  cursor: pointer;
  transition: all 0.2s ease;
}

.form-control-submit-button:hover {
  background-color: transparent;
  color: #00c9db;
}

/* Form Success And Error Message Formatting */
#cmsgSubmit.h3.text-center.tada.animated,
#pmsgSubmit.h3.text-center.tada.animated,
#cmsgSubmit.h3.text-center,
#pmsgSubmit.h3.text-center {
  display: block;
  margin-bottom: 0;
  color: #f1f1f8;
  font: 400 1.125rem/1rem 'Open Sans', sans-serif;
}

.help-block.with-errors .list-unstyled {
  color: #f1f1f8;
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: left;
}

.help-block.with-errors ul {
  margin-bottom: 0;
}
/* end of form success and error message formatting */

/* Form Success And Error Message Animation - Animate.css */
@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
/* end of form success and error message animation - Animate.css */

/* Fade Animation For Image Slider - Magnific Popup */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation: fadeIn 0.6s;
  animation: fadeIn 0.6s;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation: fadeOut 0.8s;
  animation: fadeOut 0.8s;
}
/* end of fade animation for image slider - magnific popup */

/*************************/
/*     02. Preloader     */
/*************************/
.spinner-wrapper {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #2f2c3d;
}

.spinner {
  position: absolute;
  top: 50%; /* centers the loading animation vertically one the screen */
  left: 50%; /* centers the loading animation horizontally one the screen */
  width: 3.75rem;
  height: 1.25rem;
  margin: -0.625rem 0 0 -1.875rem; /* is width and height divided by two */
  text-align: center;
}

.spinner > div {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background-color: #fff;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

/****************************/
/*     Testimonials     */
/****************************/
.slider-1 {
  padding-top: 6.875rem;
  padding-bottom: 6.375rem;
  background-color: #262431;
}

.slider-1 .slider-container {
  position: relative;
}

.slider-1 .swiper-container {
  position: static;
  width: 90%;
  text-align: center;
}

.slider-1 .swiper-button-prev:focus,
.slider-1 .swiper-button-next:focus {
  /* even if you can't see it chrome you can see it on mobile device */
  outline: none;
}

.slider-1 .swiper-button-prev {
  left: -0.5rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23f1f1f8'%2F%3E%3C%2Fsvg%3E");
  background-size: 1.125rem 1.75rem;
}

.slider-1 .swiper-button-next {
  right: -0.5rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23f1f1f8'%2F%3E%3C%2Fsvg%3E");
  background-size: 1.125rem 1.75rem;
}

.slider-1 .card {
  position: relative;
  border: none;
  background-color: transparent;
}

.slider-1 .card-image {
  width: 6rem;
  height: 6rem;
  margin-right: auto;
  margin-bottom: 0.25rem;
  margin-left: auto;
  border-radius: 50%;
}

.slider-1 .card-body {
  padding-bottom: 0;
}

.slider-1 .testimonial-author {
  margin-bottom: 0;
}

/*************************/
/*     07. Details 1     */
/*************************/
.basic-2 {
  padding-top: 8rem;
  padding-bottom: 3.5rem;
  background-color: #262431;
}

.basic-2 img {
  margin-bottom: 3.5rem;
}

.basic-2 h3 {
  margin-bottom: 1.125rem;
}

.basic-2 .btn-solid-reg {
  margin-top: 0.5rem;
}

/*************************/
/*     08. Details 2     */
/*************************/
.basic-3 {
  padding-top: 3.5rem;
  padding-bottom: 7.25rem;
  background-color: #262431;
}

.basic-3 .text-container {
  margin-bottom: 3.5rem;
}

.basic-3 h3 {
  margin-bottom: 1.125rem;
}

.basic-3 .btn-solid-reg {
  margin-top: 0.5rem;
}

/***************************/
/*     10. Screenshots     */
/***************************/

/**************************/
/*     12. Statistics     */
/**************************/
.counter {
  padding-top: 6.5rem;
  padding-bottom: 5.375rem;
  background-color: #262431;
  text-align: center;
}

.counter #counter .cell {
  display: inline-block;
  width: 6.25rem;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-bottom: 2rem;
}

.counter #counter .counter-value {
  color: #f1f1f8;
  font: 700 3.5rem/4.25rem 'Montserrat', sans-serif;
  vertical-align: middle;
}

.counter #counter .counter-info {
  margin-bottom: 0;
  color: #00c9db;
  font: 400 0.875rem/1.25rem 'Open Sans', sans-serif;
  vertical-align: middle;
}

/**********************************/
/*     16. Back To Top Button     */
/**********************************/
a.back-to-top {
  position: fixed;
  z-index: 999;
  right: 0.75rem;
  bottom: 0.75rem;
  display: none;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 1.875rem;
  background: #00c9db url('../assets/images/up-arrow.png') no-repeat center 47%;
  background-size: 1.125rem 1.125rem;
  text-indent: -9999px;
}

a:hover.back-to-top {
  background-color: #36edfd;
}

/***************************/
/*     17. Extra Pages     */
/***************************/
.ex-header {
  padding-top: 8rem;
  padding-bottom: 5rem;
  background: linear-gradient(to bottom right, #2c1a7e, #5557db);
  text-align: center;
}

.ex-basic-1 {
  padding-top: 2rem;
  padding-bottom: 0.875rem;
  background-color: #2f2c3d;
}

.ex-basic-1 .breadcrumbs {
  margin-bottom: 1.125rem;
}

.ex-basic-1 .breadcrumbs .fa {
  margin-right: 0.5rem;
  margin-left: 0.625rem;
}

.ex-basic-2 {
  padding-top: 4.75rem;
  padding-bottom: 4rem;
  background-color: #262431;
}

.ex-basic-2 h3 {
  margin-bottom: 1rem;
}

.ex-basic-2 .text-container {
  margin-bottom: 3.625rem;
}

.ex-basic-2 .text-container.last {
  margin-bottom: 0;
}

.ex-basic-2 .list-unstyled .fas {
  color: #00c9db;
  font-size: 0.5rem;
  line-height: 1.375rem;
}

.ex-basic-2 .list-unstyled .media-body {
  margin-left: 0.625rem;
}

.ex-basic-2 .btn-outline-reg {
  margin-top: 1.75rem;
}

.ex-basic-2 .image-container-large {
  margin-bottom: 4rem;
}

.ex-basic-2 .image-container-large img {
  border-radius: 0.25rem;
}

.ex-basic-2 .image-container-small img {
  border-radius: 0.25rem;
}

.ex-basic-2 .text-container.dark-bg {
  padding: 1.625rem 1.5rem 0.75rem 2rem;
  background-color: #f9fafc;
}

/*****************************/
/*     18. Media Queries     */
/*****************************/
/* Min-width width 768px */
@media (min-width: 768px) {
  /* General Styles */
  .p-heading {
    width: 85%;
    margin-right: auto;
    margin-left: auto;
  }

  h1 {
    font: 700 3.25rem/3.75rem 'Montserrat', sans-serif;
  }
  /* end of general styles */

  /* Testimonials */
  .slider-1 .swiper-button-prev {
    left: 1rem;
    width: 1.375rem;
    background-size: 1.375rem 2.125rem;
  }

  .slider-1 .swiper-button-next {
    right: 1rem;
    width: 1.375rem;
    background-size: 1.375rem 2.125rem;
  }
  /* end of testimonials */

  /* Extra Pages */
  .ex-header {
    padding-top: 11rem;
    padding-bottom: 9rem;
  }

  .ex-basic-2 .text-container.dark {
    padding: 2.5rem 3rem 2rem 3rem;
  }

  .ex-basic-2 .text-container.column {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
  /* end of extra pages */
}
/* end of min-width width 768px */

/* Min-width width 992px */
@media (min-width: 992px) {
  /* General Styles */
  .p-heading {
    width: 65%;
  }
  /* end of general styles */

  /* Details 1 */
  .basic-2 img {
    margin-bottom: 0;
  }

  .basic-2 .text-container {
    margin-top: 2.625rem;
  }
  /* end of details 1 */

  /* Details 2 */
  .basic-3 .text-container {
    margin-top: 2.5rem;
    margin-bottom: 0;
  }
  /* end of details 2 */

  /* Statistics */
  .counter {
    padding-top: 6rem;
    padding-bottom: 4.5rem;
  }

  .counter #counter .cell {
    width: 8rem;
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }

  .counter #counter .counter-value {
    font: 700 4.25rem/4.5rem 'Montserrat', sans-serif;
  }
  /* end of statistics */

  /* Extra Pages */
  .ex-header h1 {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }

  .ex-basic-2 {
    padding-bottom: 5rem;
  }

  .ex-basic-2 .text-container.column {
    margin-bottom: 0;
  }
  /* end of extra pages */
}
/* end of min-width width 992px */

/* Min-width width 1200px */
@media (min-width: 1200px) {
  /* General Styles */
  .p-heading {
    width: 44%;
  }
  /* end of general styles */

  /* Details 1 */
  .basic-2 .text-container {
    margin-top: 4.5rem;
    margin-left: 4rem;
    margin-right: 1.5rem;
  }
  /* end of details 1 */

  /* Details 2 */
  .basic-3 .text-container {
    margin-top: 4.5rem;
    margin-right: 3.5rem;
    margin-left: 2rem;
  }
  /* end of details 2 */

  /* Extra Pages */
  .ex-header h1 {
    width: 60%;
    margin-right: auto;
    margin-left: auto;
  }

  .ex-basic-2 .form-container {
    margin-left: 1.75rem;
  }

  .ex-basic-2 .image-container-small {
    margin-left: 1.75rem;
  }
  /* end of extra pages */
}
/* end of min-width width 1200px */

/**********************************/
/*     09. Details Lightboxes     */
/**********************************/
.lightbox-basic {
  position: relative;
  max-width: 46.875rem;
  margin: 2.5rem auto;
  padding: 3rem 1rem;
  border-radius: 0.25rem;
  background-color: #2f2c3d;
  text-align: left;
}

.lightbox-basic img {
  display: block;
  margin-right: auto;
  margin-bottom: 3rem;
  margin-left: auto;
}

.lightbox-basic h3 {
  margin-bottom: 0.625rem;
}

.lightbox-basic hr {
  width: 3.75rem;
  height: 0.125rem;
  margin-top: 0.125rem;
  margin-bottom: 1.125rem;
  margin-left: 0;
  border: 0;
  background-color: #00c9db;
  text-align: left;
}

.lightbox-basic h4 {
  margin-top: 1.75rem;
  margin-bottom: 0.75rem;
}

.lightbox-basic table {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.lightbox-basic table tr {
  line-height: 1.75em;
}

.lightbox-basic table .icon-cell {
  width: 2rem;
  padding-right: 0.25rem;
  color: #00c9db;
  text-align: center;
}

.lightbox-basic a.mfp-close.as-button {
  position: relative;
  width: auto;
  height: auto;
  margin-left: 0.375rem;
  color: #00c9db;
  opacity: 1;
}

.lightbox-basic a.mfp-close.as-button:hover {
  color: #f1f1f8;
}

.lightbox-basic button.mfp-close.x-button {
  position: absolute;
  top: -0.375rem;
  right: -0.375rem;
  width: 2.75rem;
  height: 2.75rem;
  color: #f1f1f8;
}

/* Fade-move Animation For Lightbox - Magnific Popup */
/* at start */
.my-mfp-slide-bottom .zoom-anim-dialog {
  opacity: 0;
  transition: all 0.2s ease-out;
  -webkit-transform: translateY(-1.25rem) perspective(37.5rem) rotateX(10deg);
  -ms-transform: translateY(-1.25rem) perspective(37.5rem) rotateX(10deg);
  transform: translateY(-1.25rem) perspective(37.5rem) rotateX(10deg);
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: translateY(0) perspective(37.5rem) rotateX(0);
  -ms-transform: translateY(0) perspective(37.5rem) rotateX(0);
  transform: translateY(0) perspective(37.5rem) rotateX(0);
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
  opacity: 0;
  -webkit-transform: translateY(-0.625rem) perspective(37.5rem) rotateX(10deg);
  -ms-transform: translateY(-0.625rem) perspective(37.5rem) rotateX(10deg);
  transform: translateY(-0.625rem) perspective(37.5rem) rotateX(10deg);
}

/* dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
  opacity: 0.8;
}
/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
  opacity: 0;
}
/* end of fade-move animation for lightbox - magnific popup */

@media (min-width: 768px) {
  /* Details Lightboxes */
  .lightbox-basic {
    padding: 3rem 3rem;
  }
  /* end of details lightboxes */
}

@media (min-width: 992px) {
  /* Details Lightboxes */
  .lightbox-basic img {
    margin-bottom: 0;
    margin-left: 0;
  }

  .lightbox-basic h3 {
    margin-top: 0.25rem;
  }
}
